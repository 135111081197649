<script lang="ts">
import { getShortUrl } from '@/api/common';
import { getContentDetail } from '@/api/content';
import { SearchShortUrlListDto } from '@/api/dto/common.dto';
import { ContentEntityDto } from '@/api/dto/content.dto';
import { UserInfoEntityDto } from '@/api/dto/user.dto';
import { SubscriptionEntity } from '@/api/model/subscription.entity';
import { getSubscription } from '@/api/subscription';
import ApocButton from '@/components/common/ApocButton.vue';
import ApocInput from '@/components/common/ApocInput.vue';
import ApocSelectV2, { ApocSelectPropType } from '@/components/common/ApocSelectV2.vue';
import ApocSvg from '@/components/common/ApocSvg.vue';
import ContentsOptionSelect from '@/components/common/ContentsOptionSelect.vue';
import AppConfig from '@/constants';
import { POPUP_MODE } from '@/stores/state-store';
import { initStore } from '@/stores/store-manager';
import { FEATURE_CODE, SHORT_TYPE_CODE, STATE_YN, TRACKER_TYPE_CODE } from '@/types/index';
import { IApocSelectOption } from '@/types/index';
import { getApiClient } from '@/utils/apiClient';
import { doCopyToDashboard, generateShortShareLink, loadLocalData } from '@/utils/common-util';
import { getTrackerUtil } from '@/utils/tracker-utils';
import { defineComponent, computed, onBeforeUnmount, onMounted, ref, watch } from 'vue';
import type { Options } from 'qr-code-styling';
import QRCodeStyling from 'qr-code-styling';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: 'ContentSharePopup',
  components: { ContentsOptionSelect, ApocSelectV2, ApocButton, ApocInput, ApocSvg },
  setup(props) {
    const { t } = useI18n();
    const storeManager = initStore();
    const apiClient = getApiClient(AppConfig.API_SERVER, storeManager);
    const popupLocker = document.querySelector('.popup-locker') as HTMLDivElement;
    // 콘텐츠 정보
    const contentInfo = ref(new ContentEntityDto());
    // 보여질 url
    const shareUrl = ref<string>('');
    // 페이지 선택 유무
    const checkSelectPage = ref<boolean>(false);
    // 기본 url
    const basicShortUrl = ref<string>('');
    // 페이지 리스트
    const pageList = ref<IApocSelectOption[]>([]);
    // 선택된 페이지
    const selectedPage = ref<IApocSelectOption | undefined>(undefined);
    // 아폭 플레이(플랫폼) url
    const apocPlayUrl = ref<string>('');
    // 구독제 관련
    const loginUser = ref<UserInfoEntityDto | undefined>(undefined); // 로그인 사용자
    const hasSharePermission = ref<boolean>(false); // 콘텐츠 공유 가능 여부
    const qrLoaded = ref<boolean>(false);
    // QR 옵션
    const qrOptions = {
      width: 250,
      height: 250,
      type: 'svg',
      data: '',
      image: `${AppConfig.FRONT_HOST.replace('', '')}assets/images/default/icon-logo.webp?240911`,
      dotsOptions: {
        color: '#a473ff',
        type: 'rounded',
      },
      backgroundOptions: {
        color: '#e9ebee',
      },
      imageOptions: {
        crossOrigin: 'anonymous',
        margin: 10,
      },
    };
    // QR 디자인, 생성
    const generateQr = async (qrData: string): Promise<string | undefined> => {
      const options = Object.assign({}, qrOptions, {
        image: `${AppConfig.FRONT_HOST.replace('', '')}assets/images/QR/icon-logo.webp?240911`,
        data: `${qrData}`,
        dotsOptions: {
          color: '#000',
          type: 'rounded',
        },
        cornersSquareOptions: {
          color: '#000',
          type: 'extra-rounded',
        },
        cornersDotOptions: {
          type: 'dot',
        },
        backgroundOptions: {
          color: '#fff',
        },
      }) as Options;
      const qr = new QRCodeStyling(options);
      const imgData = await qr.getRawData('png');

      if (imgData) {
        const url = URL.createObjectURL(imgData as Blob);
        const img = document.createElement('img');
        img.src = url;
        document.body.appendChild(img);
        //메모리 초기화
        img.onload = () => {
          URL.revokeObjectURL(url);
        };
        return url;
      }
      return undefined;
    };
    // QR 다운로드
    const doDownloadQR = async () => {
      const url = apocPlayUrl.value;
      const rtUrl = await generateQr(url);
      const link = document.createElement('a');
      link.setAttribute('href', rtUrl as string);
      link.setAttribute('download', 'apoc-qr-code.png');
      link.click();
      link.remove();
    };
    // 콘텐츠모드 QR 다운로드
    const doDownloadShareQR = async () => {
      const url = shareUrl.value;
      const rtUrl = await generateQr(url);
      const link = document.createElement('a');
      link.setAttribute('href', rtUrl as string);
      link.setAttribute('download', 'apoc-qr-code.png');
      link.click();
      link.remove();
    };

    // 콘텐츠 소유자의 구독제 정보 가져오기
    const getContentSharePermission = async () => {
      const contentOwnerIdx = contentInfo.value.ownerIdx || contentInfo.value.regrUserIdx;
      const subscriptionParam = new SubscriptionEntity();
      subscriptionParam.userIdx = contentOwnerIdx;
      getSubscription(apiClient, subscriptionParam)
        .then((res) => {
          if (res.resultCode !== 0) {
            console.error(res.resultCode);
            window.alert(t(`msg.${res.resultMsg}`));
            closePopup();
          } else {
            if (res.data) {
              res.data.featureList.filter((data) => {
                if (data.featureCode === FEATURE_CODE.USE_DIRECT_LINK) {
                  hasSharePermission.value = data.featureValue === STATE_YN.Y;
                  if (hasSharePermission.value) getShareUrl();
                }
              });
            }
          }
        })
        .catch((e) => {
          hasSharePermission.value = false;
        });
    };

    const copyShareUrl = () => {
      if (shareUrl.value) {
        doCopyToDashboard(shareUrl.value);
        getTrackerUtil().sendEvent(TRACKER_TYPE_CODE.CONTSHAR, shareUrl.value);
        if (!storeManager.stateStore.popupCopyState) {
          storeManager.stateStore.setPopupCopyState(true);
          setTimeout(() => {
            storeManager.stateStore.setPopupCopyState(false);
          }, 1000);
        }
      }
    };

    const copyApocPlayUrl = () => {
      if (apocPlayUrl.value) {
        doCopyToDashboard(apocPlayUrl.value);
        if (!storeManager.stateStore.popupCopyState) {
          storeManager.stateStore.setPopupCopyState(true);
          setTimeout(() => {
            storeManager.stateStore.setPopupCopyState(false);
          }, 1000);
        }
      }
    };

    const closePopup = () => {
      storeManager.dataStore.setPopupReportTargetId('');
      storeManager.dataStore.setPopupReportTargetType('');
      storeManager.stateStore.setPopupMode(POPUP_MODE.NONE);
    };

    const getContentDetailFunc = () => {
      if (storeManager.dataStore.popupContentId) {
        apocPlayUrl.value = `${AppConfig.FRONT_HOST}content/${storeManager.dataStore.popupContentId}`;
        const param = new ContentEntityDto();
        param.contentIdx = storeManager.dataStore.popupContentId;

        getContentDetail(apiClient, param)
          .then(async (res) => {
            if (res.resultCode === 0) {
              if (res.data) {
                contentInfo.value = res.data;
                if (contentInfo.value.shortUrl) {
                  basicShortUrl.value = contentInfo.value.shortUrl;
                }
                if (contentInfo.value.shareStartPageList && contentInfo.value.shareStartPageList.length > 0) {
                  const canvasList: IApocSelectOption[] = [];
                  contentInfo.value.shareStartPageList.map((uuid, index) => {
                    canvasList.push({
                      value: uuid,
                      valueLabel: `${index + 1} ${t('popup.shareLinkPopup.page')}`,
                      listLabel: `${index + 1} ${t('popup.shareLinkPopup.page')}`,
                      uuid: uuid,
                    });
                  });
                  pageList.value = canvasList;
                }
                // 구독제 오픈에 따라 isActivesubscription 삭제
                await getContentSharePermission();
              }
            } else {
              console.error(res.resultCode);
              window.alert(t(`msg.${res.resultMsg}`));
              closePopup();
            }
          })
          .catch((e) => {
            closePopup();
          });
      }
    };

    const getShareUrl = () => {
      if (checkSelectPage.value && selectedPage.value) {
        const searchParam = new SearchShortUrlListDto();
        searchParam.targetUrl = storeManager.dataStore.popupContentId + '?page=' + selectedPage.value?.value;
        searchParam.shortTypeCode = SHORT_TYPE_CODE.URL;
        getShortUrl(apiClient, searchParam)
          .then((res) => {
            if (res.resultCode === 0 && res.data && res.data.shortIdx) {
              shareUrl.value = generateShortShareLink(res.data.shortIdx, true);
            } else {
              shareUrl.value = '';
            }
          })
          .catch((err) => {
            shareUrl.value = '';
          });
      } else {
        // 콘텐츠 모드 링크 값이 없는 경우 닫게 끔 설정
        if (!basicShortUrl.value) {
          hasSharePermission.value = false;
          return;
        }
        shareUrl.value = basicShortUrl.value ? generateShortShareLink(basicShortUrl.value, true) : '';
      }
    };

    const getLoginUserInfo = () => {
      const user = loadLocalData(AppConfig.KEYS.CONST.LOGIN_USER);
      if (user) {
        loginUser.value = JSON.parse(user);
      }
    };

    const handleSelectPage = (v: ApocSelectPropType) => {
      const selectedValue = pageList.value.filter((page) => page.value === v);
      selectedPage.value = selectedValue.length > 0 ? selectedValue[0] : pageList.value[0];
      // 만약 아무것도 선택 안하면 무조건 첫번째 페이지 default 선택되도록
      getShareUrl();
    };

    watch(
      () => checkSelectPage.value,
      (next, prev) => {
        if (next && pageList.value.length > 0) {
          selectedPage.value = pageList.value[0];
        }
        if (hasSharePermission.value) getShareUrl();
      },
    );

    onMounted(() => {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100%';
      document.body.style.width = '100%';
      getLoginUserInfo();
      getContentDetailFunc();
    });

    onBeforeUnmount(() => {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'unset';
      document.body.style.width = 'unset';
    });

    return {
      t,
      apocPlayUrl,
      checkSelectPage,
      hasSharePermission,
      pageList,
      popupLocker,
      qrLoaded,
      selectedPage,
      shareUrl,
      closePopup,
      copyApocPlayUrl,
      copyShareUrl,
      doDownloadQR,
      doDownloadShareQR,
      handleSelectPage,
      generateQr,
    };
  },
});
</script>

<template>
	<div class="share-link-popup">
		<apoc-svg class="close-btn" src="assets/images/icon-mypage/icon-close.svg" @click="closePopup" />
		<div class="title">{{ t('popup.shareLinkPopup.share') }}</div>
		<div class="link-popup-gap">
			<div class="link-info-area">
				<div class="share-link-label">{{ t('popup.shareLinkPopup.apocPlayLink') }}</div>
				<div class="share-link-info">
					<div class="link-wrapper">
						<input class="link-url" :value="apocPlayUrl" readonly />
						<div class="link-info">{{ t('popup.shareLinkPopup.playPage') }}</div>
					</div>
          <div class="QR-wrapper">
            <button class="link-copy-qr" @click="doDownloadQR">
              <apoc-svg src="assets/images/QR/qr-code.svg"/>
              <div class="qr-canvas" :class="{isShow : qrLoaded}"></div>
            </button>
            <span class="tooltip-text">{{ t('popup.shareLinkPopup.downQR') }}</span>
          </div>
					<button class="link-copy-btn" @click="copyApocPlayUrl">{{ t('popup.shareLinkPopup.copy') }}</button>
				</div>
			</div>
		</div>
		<!-- 구독제일때 -->
		<div v-if="hasSharePermission" class="link-info-area play-mode">
			<div class="share-link-label">{{ t('popup.shareLinkPopup.contentModeLink') }}
      <div class="share-link-disc">{{ t('popup.shareLinkPopup.apocContentdisc') }}</div>
    </div><div class="share-link-info">
				<div class="link-wrapper">
					<input class="link-url" :value="shareUrl" readonly />
					<div class="link-info">{{ t('popup.shareLinkPopup.contentMode') }}</div>
				</div>
        <div class="QR-wrapper">
            <button class="link-copy-qr" @click="doDownloadShareQR">
              <apoc-svg src="assets/images/QR/qr-code.svg"/>
              <div class="qr-canvas" :class="{isShow : qrLoaded}"></div>
            </button>
            <span class="tooltip-text">{{ t('popup.shareLinkPopup.downQR') }}</span>
          </div>
				<button class="link-copy-btn" @click="copyShareUrl">{{ t('popup.shareLinkPopup.copy') }}</button>
			</div>
			<div class="share-link-desc">{{ t('popup.shareLinkPopup.shortUrlDesc') }}</div>
			<div v-if="pageList.length > 1" class="share-link-select">
				<input id="share-link" v-model="checkSelectPage" type="checkbox" />
				<label for="share-link">{{ t('popup.shareLinkPopup.selectStartPage') }}</label>
				<apoc-select-v2
					v-if="checkSelectPage"
					class="page-select-list"
					:option-list="pageList"
					:select-option-parent="popupLocker"
					:set-max-height-to-bottom="true"
					:option-class-list="['share-link-select-option']"
					:selected-value="selectedPage"
					:show-check="true"
					:right-check="true"
					:on-change="handleSelectPage" />
			</div>
		</div>
	</div>
</template>

  
 