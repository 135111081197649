import { KEYS } from './constants-keys';

export const APP_ENV_TYPE = {
	DEV: 'development',
	STAGE: 'staging',
	PROD: 'production',
};

const AppConfig = {
	ENV: APP_ENV_TYPE.DEV,
	API_SERVER: 'https://api-dev.apoc.day/api/v2',
	CHAT_URL: 'https://chat-dev.apoc.day',
	CHAT_SERVER: 'https://chat-dev.apoc.day',
	FRONT_HOST: 'https://dev.apoc.day/',
	AUTHOR_HOST: 'https://studio-dev.apoc.day/#/',
	ASSET_HOST: 'https://asset-dev.apoc.day/',
	COMM_HOST: 'https://community-dev.apoc.day/',
	BRAND_HOST: 'https://brand.apoc.day/',
	MY_PAGE_HOST: 'https://my-dev.apoc.day/',
	FILE_SERVER: 'https://cdn.apoc.day/',
	TRACKER_URL: 'https://cdn.apoc.day/js/tracker-dev.js?20240415',
	SSO_API_SERVER: 'https://sso-dev.apoc.day',
	PAYMENT_SERVER: 'https://payment-dev.apoc.day/api/v2',
	EXTERNAL_API_SERVER: 'https://extern-api.apoc.day/api',
	KEYS,
};
export default AppConfig;
